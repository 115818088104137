import { createPopup } from "@picmo/popup-picker";
import { createPicker, i18n } from "picmo";

(function () {
    window.emoji = {
        createPicker,
        createPopup,
        i18n: {
            de: {
                ...i18n.de,
            },
            en: {
                ...i18n.en,
            },
        },
    };
})();
